<template>
  <div class="left">
    <div class="area">
      <div class="area_title">
        楼宇自控
        <div class="area_title_icon"></div>
        <div class="area_title_line">
          <div class="area_title_line_ract"></div>
        </div>
      </div>
      <div class="area_content">
        <div class="building_chose_">
          <el-select
              v-model="modeName"
              style="width: 230px"
              placeholder="选择教学楼"
              placeholder-class="placeholderStyle"
              @change="choseMode(modeName)"
              :teleported="false"
          >
            <el-option
                v-for="item in router"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            />
          </el-select>
        </div>
      </div>
    </div>
    <div class="area">
      <div class="area_title_">
        舒适度分析
        <div class="area_title_icon"></div>
        <div class="area_title_line">
          <div class="area_title_line_ract"></div>
        </div>
      </div>
      <div class="area_content" style="padding-top: 0;display: flex;justify-content: center">
        <div class="list_con">
          <div class="list_row" style="border-bottom: 1px solid #838383;">
            <div class="list_col" style="background-color: #30D6C2;border-right: 1px solid #838383;color: #103632">
              温度范围
            </div>
            <div class="list_col" style="background-color: #30D6C2; color: #103632">比例（%）</div>
          </div>
          <div class="list_row">
            <div class="list_col">20℃以下</div>
            <div class="list_col">20</div>
          </div>
          <div class="list_row">
            <div class="list_col">20℃~26℃</div>
            <div class="list_col">70</div>
          </div>
          <div class="list_row">
            <div class="list_col">26℃以上</div>
            <div class="list_col">10</div>
          </div>
        </div>
      </div>
    </div>
    <div class="area">
      <div class="area_title_">
        空调设备分析
        <div class="area_title_icon"></div>
        <div class="area_title_line">
          <div class="area_title_line_ract"></div>
        </div>
      </div>
      <div class="area_content" style="padding-top: 0;">
        <div class="pie_con">
          <div id="pie2" style="width: 100%;height: 180px;"></div>
          <div class="pie_num">98%</div>
          <div class="pie_label">在线率</div>
          <div class="label_container">
            <div class="lebal">
              <div class="label_icon" style="background-color: #FFF;"></div>
              <div class="label_text">总数</div>
              <div class="label_text">1225个</div>
              <div class="label_line"></div>
            </div>
            <div class="lebal">
              <div class="label_icon" style="background-color: #30D6C2;"></div>
              <div class="label_text" style="color: #30D6C2;">在线</div>
              <div class="label_text" style="color: #30D6C2;">1220个</div>
              <div class="label_line"></div>
            </div>
            <div class="lebal">
              <div class="label_icon" style="background-color: #4F4F4F;"></div>
              <div class="label_text">离线</div>
              <div class="label_text">5个</div>
              <div class="label_line"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ChoseBuilding :airInfo="airInfo" @getAirInfo="getAirInfo"></ChoseBuilding>
  </div>
  <div class="right">
    <div class="area" style="height: 100%;position: relative;">
      <div class="area_title" style="padding-left: 0px;">
        空调设备列表
        <div class="area_title_icon"></div>
        <div class="area_title_line">
          <div class="area_title_line_ract"></div>
        </div>
      </div>
      <div class="search">
        <input type="text" v-model="devName" @input="findDev" placeholder="输入设备名称">
      </div>
      <div class="warn_title">
        <div class="warn_title_text" style="width: 32px;">序号</div>
        <div class="warn_title_text" style="width: 80px;">设备名称</div>
        <div class="warn_title_text" style="width: 80px;">设备状态</div>
        <div class="warn_title_text" style="width: 80px;">详细信息</div>
      </div>
      <div class="warn_container">
        <div v-for="(item,index) of devList" :key="index">
          <div class="warn_item" :class="index%2===0?'bg':''">
            <div class="warn_text" style="width: 32px;">{{ index + 1 }}</div>
            <div class="warn_text" style="width: 80px;" :style="{color:color[item.color]}">
              <div class="ract_left" :style="{backgroundColor:color[item.color]}"></div>
              <div class="ract_right" :style="{backgroundColor:color[item.color]}"></div>
              {{ item.name }}
            </div>
            <div class="warn_text" style="width: 80px;">{{ item.state ? "在线" : "离线" }}</div>
            <div class="warn_text" style="width: 80px;color: #30D6C2;cursor: pointer" @click="checkDev(item)">查看</div>
          </div>
          <div class="info" v-if="item.show">
            <div class="info_row">
              <div class="info_col">设备型号：{{ item.devtype }}</div>
              <div class="info_col">安装日期：{{ item.date }}</div>
            </div>
            <div class="info_row">
              <div class="info_col">地址：{{ item.isOutside ? "室外" : item.build + item.floor + item.room }}</div>
              <div class="info_col info_locate" @click="locateModel(item)"><div class="position_icon"></div>定位</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="pop_air" id="air_pop" v-show="airInfo.isAir">
    <div class="close-icon" @click="airInfo.isAir=false"></div>
    <div class="header">
      <p class="text">{{ airInfo.equipmentName }}</p>
    </div>
    <div class="content">
      <div class="content_">
        <div class="content_1">
          <p class="text_">位置：</p>
          <p class="text_3">{{ airInfo.location }}</p>
        </div>
        <div class="content_1">
          <p class="text_">状态：</p>
          <p class="text_2">在线</p>
        </div>
      </div>
      <div class="content_">
        <div class="content_1">
          <p class="text_">温度：</p>
          <p class="text_3">20℃</p>
        </div>
        <div class="content_1">
          <p class="text_">湿度：</p>
          <p class="text_3">60%</p>
        </div>
      </div>
      <div class="content_">
        <div class="content_1">
          <p class="text_">开关：</p>
          <div class="switch">
            <el-switch
                v-model="isWind"
                style="--el-switch-on-color: #30D6C2;"
                @change="isWind=!isWind"
            />
          </div>
        </div>
        <div class="content_1">
          <p class="text_">设定温度：</p>
          <input type="number" class="temp_input" placeholder="20℃"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import ChoseBuilding from "../ChoseBuilding";
import Global from "@/js/global";
import Message from "../../js/Message";

export default {
  name: "Lift",
  components: {ChoseBuilding},
  data() {
    return {
      isAir: true,
      focus: false,
      modeName: "AirConditioning",
      name: "",
      devName: "",
      router: [
        {label: 'CO浓度监测', value: 'CO'},
        {label: '集水坑监测', value: 'Water'},
        {label: '送排风', value: 'ExhaustAir'},
        {label: '电梯', value: 'Lift'},
        {label: '空调', value: 'AirConditioning'},
        {label: '柴油发电机', value: 'Electricity'},
      ],
      devList: [],
      allDevList: [],
      color: ["#D27D69", "#F8D8B0", "#30D6C2", "#BF79AF"],
      choseIndex: 1,
      airInfo: {
        classroomNumber: "",
        equipmentName: "",
        isAir: false,
        chosePoint: "",
        location: ""
      },
    };
  },
  created() {
    this.$nextTick(() => {
      this.initPie2();
    });
    this.getPoints();
  },
  mounted() {
  },
  unmounted() {
    this.airInfo.isAir = false
    if (window.myChart_2) {
      window.myChart_2.dispose();
      window.myChart_2 = null;
    }
  },
  watch: {
    "airInfo.isAir": {
      handler: function (newValue, oldValue) {
        if (newValue) {
          Message.setPopPosition("air_pop", this.airInfo.chosePoint)
          let vector2 = Global.vector3Tovector2(this.airInfo.chosePoint)
          new Global.DragAndZoom("air_pop", vector2)
        } else {
          scene.onBeforeRenderObservable.remove(Message.observer)
        }
      }
    }
  },
  methods: {
    getAirInfo(info) {
      this.airInfo = info
    },
    choseMode(path) {
      this.$router.push({path: path})
    },
    getPoints() {
      let dev = Global.findEquipment("light");
      for (let item of dev) {
        item.date = "2022-10-08";
      }
      this.devList = dev;
      this.allDevList = [...dev];
    },
    findDev() {
      let arr = [];
      if (this.devName) {
        for (let dev of this.allDevList) {
          let i = dev.name.indexOf(this.devName);
          if (i > -1) {
            arr.push(dev)
          }
        }
      } else {
        arr = [...this.allDevList];
      }
      this.devList = arr;
    },
    checkDev(data) {
      let flag = data.show
      for (let item of this.devList) {
        item.show = false
      }
      data.show = !flag
    },
    choseLabel(data) {
      this.name = data.name;
      this.focus = false;
    },
    initPie2() {
      let container = document.getElementById("pie2");
      let myChart_2 = echarts.init(container);
      // 绘制图表
      let option = {
        series: [
          {
            type: "pie",
            radius: ["45%", "75%"],
            center: ["27%", "45%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center"
            },
            labelLine: {
              show: false
            },
            data: [{
              value: 0.4,
              itemStyle: {
                color: "transparent"
              }
            },
              {
                value: 99.6,
                name: "rose2",
                itemStyle: {
                  color: "#30D6C2"
                }
              }
            ]
          },
          {
            type: "pie",
            radius: ["45%", "75%"],
            center: ["27%", "45%"],
            label: {
              show: false,
              position: "center"
            },
            labelLine: {
              show: false
            },
            data: [{
              value: 0.4,
              itemStyle: {
                color: "#4F4F4F"
              }
            },
              {
                value: 99.6,
                itemStyle: {
                  color: "transparent"
                }
              }
            ]
          },
          {
            type: "pie",
            radius: ["45%", "50%"],
            center: ["27%", "45%"],
            label: {
              show: false,
              position: "center"
            },
            labelLine: {
              show: false
            },
            data: [{
              value: 0.4,
              itemStyle: {
                color: "#fff"
              }
            },
              {
                value: 99.6,
                itemStyle: {
                  color: "transparent"
                }
              }
            ]
          }
        ]
      };
      myChart_2.setOption(option);
      window.myChart_2 = myChart_2;
    },
    locateModel(data) {
      Global.choseEquipment(data);
    }
  }
};
</script>

<style scoped>
.pop_air {
  position: fixed;
  left: 40%;
  top: 40%;
  width: calc(584px / 3 * 2);
  height: calc(323px / 3 * 2);
  background-color: rgba(255, 255, 255, 0.0);
  -webkit-background-size: 100% 100%;
  background-image: url("../../assets/image/bg/pop_air.png");
  background-size: 100% 100%;
}

.content_1 {
  display: flex;
  width: 50%;
  align-items: center;
}

.temp_input {
  width: 60px;
  height: 100%;
  border: none;
  background-color: rgba(0, 0, 0, 0);

  font-family: 'FZZhengCu';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #30D6C2;
}

.list_col {
  height: 40px;
  width: 120px;
  border-right: 1px solid #4CB0A4;
  text-align: center;
  line-height: 40px;
  font-size: 16px;
  color: #FFFFFF;
}

.list_row {
  display: flex;
  border-bottom: 1px solid #4CB0A4;
}

.list_col:last-child, .list_row:last-child {
  border-right: none;
  border-bottom: none;
}

.list_con {
  border: 1px solid #30D6C2;
  border-radius: 5px;
  margin-top: 15px;
}

.info_col {
  font-size: 12px;
  color: #30D6C2;
}

.info {
  width: calc(100% - 20px);
  height: 60px;
  border: 1px solid #30D6C2;
  margin-left: 10px;
  background-color: rgba(14, 40, 37, 0.5);
  display: flex;
  flex-direction: column;
  padding: 0 15px;
  justify-content: space-around;
}

.info_row {
  display: flex;
  justify-content: space-between;
}

.bg {
  background-image: linear-gradient(to right, rgba(88, 173, 169, 0.7) 0%, rgba(19, 21, 21, 0.1) 100%);
}

.search {
  position: absolute;
  right: 15px;
  top: -8px;
  width: 130px;
  height: 35px;
}

.search input {
  width: calc(100% - 30px);
  height: 100%;
  padding: 0 15px;
  border: 1px solid #30D6C2;
  border-radius: 5px;
  color: #fff;
  font-size: 16px;
  background-color: transparent;
}

.warn_container {
  width: 100%;
  height: calc(100% - 100px);
  overflow: scroll;
}

.warn_item {
  display: flex;
  width: 100%;
  padding: 0 10px;
  justify-content: space-between;
  height: 27px;
  align-items: center;
  margin: 10px 0;
}

.warn_item .warn_text {
  color: #ffffff;
  font-size: 12px;
  text-align: center;
  position: relative;
}

.warn_title_text {
  font-size: 16px;
  color: #fff;
  text-align: center;
}

.warn_title {
  width: 100%;
  height: 37.5px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  justify-content: space-between;
  background-color: #4CB0A4;
  /*background-image: radial-gradient(rgba(88, 173, 169, 0.6), rgba(19, 21, 21, 0.1));*/
}

.video_name {
  color: #fff;
}

.video {
  margin-bottom: 15PX;
}


.video_container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.pie_num {
  width: 80px;
  height: 40px;
  color: #30D6C2;
  font-size: 16px;
  text-align: center;
  line-height: 40px;
  position: absolute;
  left: 53px;
  top: 61px;
}

.pie_label {
  width: 120px;
  height: 25px;
  position: absolute;
  left: 31.5px;
  bottom: 3px;
  color: #fff;
  font-size: 16px;
  text-align: center;
  line-height: 25px;
}

.label_line {
  width: 100%;
  bottom: 0;
  height: 1px;
  position: absolute;
  left: 0;
  background-image: linear-gradient(to right, rgba(144, 249, 252, 1) 0%, rgba(125, 125, 125, 0.4) 100%);
}

.label_text {
  margin-top: 4px;
  font-size: 14px;
  color: #ffffff;
}

.label_icon {
  width: 14px;
  height: 14px;
  margin-left: 6.5px;
  background-color: #fff;
}

.lebal {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  height: 47px;
}

.label_container {
  position: absolute;
  width: 160px;
  right: 10px;
  top: 25px;
}

.pie_con {
  width: 100%;
  position: relative;
}


.floor {
  width: 33.33%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 35px;
}

.floors {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  height: 160px;
  overflow: scroll;
}

.whirling {
  transform: rotateZ(-180deg);
}

.select_:first-child {
  margin-top: 10px;
}

.select_:last-child {
  margin-bottom: 10px;
}

.select_ {
  padding: 15px 30px;
  color: #1EBCBF;
  cursor: pointer;
}

.select_con {
  position: absolute;
  width: 130%;
  height: 200px;
  left: 0;
  top: 50px;
  z-index: 2;
  background-color: #fff;
  border-radius: 5px;
  overflow: scroll;
  transition: all 0.3s;
}


.b_input ._input {
  width: 100%;
  height: 100%;
  border: 1px solid #30D6C2;
  border-radius: 5px;
  padding: 0 15px;
  background-color: transparent;
  color: #ffffff;
  cursor: pointer;
  line-height: 46px;
  font-size: 18px;
}

.b_input {
  position: relative;
  width: 150px;
  height: 46px;
  margin-left: 20px;
}


.overview_left {
  margin-right: 14px;
}

.text {
  font-size: 24px;
  color: #30D6C2;
  margin-bottom: 5px;
}

.text_tip {
  font-size: 14px;
  color: #F2F2F2;
}

.overview_icon {
  width: 20px;
  height: 20px;
}

.circle {
  width: 19px;
  height: 19px;
  margin-bottom: 10px;
  background-color: rgba(48, 204, 214, 0.3);
  border-radius: 50%;
}

.area_content {
  padding: 20px 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 0px;
}

.area_content_ {
  padding: 20px 10px;
  padding-bottom: 0px;
}

.overview {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  width: 138px;
}
</style>
